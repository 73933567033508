export default {
    name: 'radar-digital',
    components: {
        contact: () => import('@/components/contact')
    },
    data() {
        return {
            current: 1,
            scrolled: false,
            note: 1,
            privacy: false,
            menu: false
        }
    },
    mounted() {
        document.addEventListener('scroll', this.scrollHandler)
        this.scrollHandler()
    },
    methods: {
        scrollHandler: function(evt) {
            this.scrolled = window.scrollY >= 100;
        },
        slide: function(slide) {

        }
    }
}